.App {
  text-align: center;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  transition: background-color 0.3s, color 0.3s;
}

.App-header {
  padding: 2rem;
  border-radius: 1rem;
  transition: background-color 0.3s;
}

.App-link {
  margin: 1rem;
  padding: 0.5rem 1rem;
  border: 2px solid currentColor;
  border-radius: 0.3rem;
  text-decoration: none;
  transition: color 0.3s, border-color 0.3s;
  &:hover,
  &:focus {
    color: #61dafb;
    border-color: #61dafb;
  }
}

/* // Define default light mode colors */
:root {
  --background-color: #ffffff;
  --text-color: #333333;
  --link-color: #61dafb;
  --header-background: rgba(0, 0, 0, 0.05);
  --header-shadow: rgba(0, 0, 0, 0.2);
}

/* // Apply the light mode colors by default */
body {
  background-color: var(--background-color);
  color: var(--text-color);
}

.App {
  background-color: var(--background-color);
  color: var(--text-color);
}

.App-header {
  background-color: var(--header-background);
  box-shadow: 0 2px 10px var(--header-shadow);
}

.App-link {
  color: var(--link-color);
}

/* // Respond to the user's preference for dark mode */
@media (prefers-color-scheme: dark) {
  /* // Define dark mode colors */
  :root {
    --background-color: #282c34;
    --text-color: #ffffff;
    --link-color: #61dafb;
    --header-background: rgba(255, 255, 255, 0.1);
    --header-shadow: rgba(255, 255, 255, 0.05);
  }
}